
















import {Vue, Component} from "vue-property-decorator";
import BillSucListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import BillSucItem from "@/views/Money/BillSuc/components/Item/index.vue";
import {getBillSuc} from '../../Server';
import {BillSucStore} from "@/views/Money/BillSuc/Store";

@Component({name: "BillSucList", components: {PullDownUpList, ListBottom, BillSucItem}})
export default class BillSucList extends Vue implements BillSucListType {
    id = ""
    PullDown = false
    StopUp = false
    UpDownBool = false
    List:any[] = []

    activated() {
        let {data = ""} = this.$route.query
        let JsonData = JSON.parse(data as string)
        this.id = JsonData.id as string
        this.handleUpData()
    }

    handlePullDown(): void {
        this.PullDown = true
        this.handleUpData()
    }

    handleUpDown(): void {
        //
    }

    handleUpData(bool = false): void {
        getBillSuc(this.id).then(res => {
            this.PullDown = false
            this.UpDownBool = false
            this.List = res
            BillSucStore.SetBillSucStoreListNum(res.length || 0)
        })
    }
}
