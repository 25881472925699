















import {Vue, Component} from "vue-property-decorator";
import BillSucType from "./BillSuc";
import ZoomPage from "../../../impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import BillSucHead from "@/views/Money/BillSuc/components/Head/index.vue"
import MoneyTitle from "@/views/Money/components/Title/index.vue";
import BillSucList from "@/views/Money/BillSuc/components/List/index.vue";
import { TitleList } from './Model';

@Component({name: "BillSuc",components:{ HeadTop,BillSucHead,MoneyTitle,BillSucList }})
export default class BillSuc extends ZoomPage implements BillSucType{
    TitleList = TitleList

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let { data = "" } = this.$route.query
        data = JSON.parse(data as string)
    }
}
