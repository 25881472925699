



















import {Vue, Component, Prop} from "vue-property-decorator";
import BillSucItemType from "./indexType";
import {typeList} from "@/views/Money/BillSuc/Model";

@Component({name: "BillSucItem"})
export default class BillSucItem extends Vue implements BillSucItemType{
    RightIcon = require("@/assets/icon/Basics/Right.png")

    @Prop(Object)
    data!:any
    get getData(){
        return this.data
    }

    @Prop(Number)
    index!:number
    get getIndex(){
        return this.index
    }

    get getTypeStr(){
        return this.getData && this.getData["transationType"] && typeList[ this.getData["transationType"] ] || typeList['1']
    }
}
