























import {Vue, Component} from "vue-property-decorator";
import BillSucHeadType from "./indexType";
import {BillSucStore} from "@/views/Money/BillSuc/Store";

@Component({name: "BillSucHead"})
export default class BillSucHead extends Vue implements BillSucHeadType{
    data = {}

    activated(){
        let { data = "" } = this.$route.query
        this.data = JSON.parse(data as string)
    }

    get getBillNum(){
        return BillSucStore.getBillSucStoreListNum
    }
}
